<template>
  <div class="withdraw-detail">
    <b-row>
      <b-col
        cols="24"
        md="12"
      >
        <b-card title="Basic Info">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <div class="basic-content">
            <div>
              <b-card-text>Transaction Id: {{ basicInfo.transactionId }}</b-card-text>
              <b-card-text>Wallet status: {{ basicInfo.withdrawStatus }}</b-card-text>
              <b-card-text>Channel: {{ basicInfo.withdrawChannel }}</b-card-text>
              <b-card-text>Processing fee: {{ basicInfo.fee }}</b-card-text>
            </div>
            <div>
              <b-card-text>Channel Status: {{ basicInfo.partnerStatus }}
                <b-button
                  v-if="basicInfo.partnerStatus == 'PROCESSING'"
                  variant="primary"
                  @click="checkStatus"
                >check</b-button>
              </b-card-text>
              <b-card-text>Amount: {{ basicInfo.amount }}</b-card-text>
              <b-card-text>Currency：{{ basicInfo.currency }}</b-card-text>
              <b-card-text>Channel ID: {{ basicInfo.partnerId }}</b-card-text>
            </div>
            <div>
              <b-card-text>Total Amount: {{ basicInfo.totalAmount }}</b-card-text>
              <b-card-text>Create time: {{ createTime }}</b-card-text>
              <b-card-text>Update time: {{ updatedTime }}</b-card-text>
              <b-card-text>Bank Statement Id: {{ basicInfo.centralBankId }}</b-card-text>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="User Info">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-card-text>Country：{{ userInfo.country }}</b-card-text>
          <!-- class="text-primary" -->
          <b-card-text>User ID: {{ userInfo.uuid }}</b-card-text>
          <b-card-text>Document type: {{ userInfo.documentType }}</b-card-text>
          <b-card-text>Document ID: {{ documentId_f }}</b-card-text>
          <b-card-text>Email: {{ userInfo.email }}</b-card-text>
          <b-card-text>Cep: {{ userInfo.cep }}</b-card-text>
          <b-card-text>Phone number: {{ phone_f }}</b-card-text>
        </b-card>
        <b-card title="History">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-table
            :items="history"
            :fields="fields"
            responsive
            class="history-table"
          >
            <template #row-details="row">
              <b-card>
                <b-row class="mb-2">
                  <b-col
                    md="8"
                    class="mb-1"
                  >
                    <strong>Status: </strong>
                  </b-col>
                  <b-col
                    md="8"
                    class="mb-1"
                  >
                    <strong>Date: </strong>
                  </b-col>
                  <b-col
                    md="8"
                    class="mb-1"
                  >
                    <strong>Remark: </strong>
                  </b-col>
                </b-row>
              </b-card>
            </template>
          </b-table>
        </b-card>
        <!-- <b-card title="Operation">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <b-form inline
                  class="userinfo-form">
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Select status：</label>
            <v-select v-model="operationForm.selectStatus"
                      :options="selectStatusOption"
                      class="invoice-filter-select mb-1 mr-sm-1"
                      placeholder="All">
              <template #selected-option="{ label }">
                <span class="text-truncate overflow-hidden">
                  {{ label }}
                </span>
              </template>
            </v-select>
            <br />
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Remark：</label>
            <b-form-input v-model="operationForm.remark"
                          class="d-inline-block mr-1 mb-1"
                          placeholder="" />
            <br />
            <label class="mr-sm-1 mb-1"
                   for="inline-form-custom-select-pref">Password：</label>
            <b-form-input v-model="operationForm.password"
                          class="d-inline-block mr-1 mb-1"
                          placeholder="" />
            <b-button variant="primary"
                      class="mb-1 mr-2">Submit</b-button>
          </b-form>
        </b-card> -->
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card title="Beneficiary account">
          <b-col cols="24 pt-0">
            <hr>
          </b-col>
          <!-- 转出渠道(1 银行卡 2 picpay 3 pix 4 mercadopago) -->
          <div v-if="beneficiaryAccount.recipientType == 1">
            <b-card-text>Bank name: {{ beneficiaryAccount.bankName }}</b-card-text>
            <!-- class="text-primary" -->
            <b-card-text>Bank code: {{ beneficiaryAccount.bankCode }}</b-card-text>
            <b-card-text>Document type: {{ beneficiaryAccount.documentType }}</b-card-text>
            <b-card-text>Docuemnt ID: {{ beneficiaryAccount.documentId }}</b-card-text>
            <b-card-text>Beneficiary name:
              {{ beneficiaryAccount.beneficiaryName }}</b-card-text>
            <b-card-text>Bank branch: {{ beneficiaryAccount.branch }}</b-card-text>
            <b-card-text>Account number:
              {{ beneficiaryAccount.accountNumber }}</b-card-text>
            <b-card-text>Account digit: {{ beneficiaryAccount.accountDigit }}</b-card-text>
            <b-card-text>Account type: {{ beneficiaryAccount.accountType }}</b-card-text>
          </div>
          <div v-else-if="beneficiaryAccount.recipientType == 2">
            <b-card-text>Picpay account:
              {{ beneficiaryAccount.picpayAccount }}</b-card-text>
          </div>
          <div v-else-if="beneficiaryAccount.recipientType == 3">
            <b-card-text>PIX type：{{ beneficiaryAccount.pixType }}</b-card-text>
            <b-card-text>PIX key：{{ pixkey }}</b-card-text>
          </div>
          <div v-else-if="beneficiaryAccount.recipientType == 4">
            <b-card-text>Email：{{ beneficiaryAccount.email }}</b-card-text>
          </div>
          <div v-else />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BTable,
  BForm,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import {
  getQueryString,
  isDefine,
  formatTimeReg,
  updateDate,
  formatDocumentId,
  formatPhoneNumber,
  showToast,
} from '@/libs/utils'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BTable,
    BForm,
    vSelect,
    BFormInput,
    BButton,
  },
  props: {},
  data() {
    return {
      fields: ['status', 'created_at', 'message'],
      selectStatusOption: ['Fail', 'Created', 'Processing', 'Success'],
      operationForm: {
        selectStatus: null,
        remark: null,
        password: null,
      },
      basicInfo: {},
      userInfo: {},
      beneficiaryAccount: {},
      history: [],
    }
  },
  computed: {
    createTime() {
      return updateDate(this.basicInfo.createdAt, getQueryString('timezone'))
    },
    updatedTime() {
      return updateDate(this.basicInfo.updatedAt, getQueryString('timezone'))
    },
    documentId_f() {
      return formatDocumentId(this.userInfo.documentId, this.basicInfo.currency)
    },
    phone_f() {
      return formatPhoneNumber(this.userInfo.phone)
    },
    pixkey() {
      if (this.beneficiaryAccount.pixType == 'PHONE') {
        return formatPhoneNumber(this.beneficiaryAccount.pixVal)
      }
      if (
        this.beneficiaryAccount.pixType == 'CPF'
        || this.beneficiaryAccount.pixType == 'CNPJ'
      ) {
        return formatDocumentId(
          this.beneficiaryAccount.pixVal,
          this.basicInfo.currency,
        )
      }
      return this.beneficiaryAccount.pixVal
    },
  },
  mounted() {
    this.initWithdrawDetail()
  },
  methods: {
    initWithdrawDetail() {
      this.$showLoading()
      const { id } = this.$route.query
      this.$http
        .get(`/api/v1/withdraw/withdrawDetail?transactionId=${id}`)
        .then(response => {
          this.$hideLoading()
          if (response.data.code == 200) {
            const {
              basicInfo, userInfo, beneficiaryAccount, history,
            } = response.data.data
            this.basicInfo = basicInfo
            this.userInfo = userInfo
            this.beneficiaryAccount = beneficiaryAccount
            this.history = this.handlerHistory(history)
          } else {
            showToast(this, 'warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          showToast(this, 'warning', error.message)
        })
    },
    handlerHistory(bean) {
      if (!bean.partnerReturn) return
      let historyArray = []
      if (
        Object.prototype.toString.call(bean.partnerReturn) == '[object Array]'
      ) {
        bean.partnerReturn.forEach(o => {
          if (o) {
            historyArray.push(this.handlerHistoryObject(o, bean))
          }
        })
        historyArray = historyArray.filter(
          value => value.status || value.createdAt || value.message,
        )
      } else if (
        Object.prototype.toString.call(bean.partnerReturn) == '[object Object]'
      ) {
        historyArray = new Array(
          this.handlerHistoryObject(bean.partnerReturn, bean),
        )
      } else {
      }

      return historyArray
    },
    handlerHistoryObject(o, bean) {
      const h = {}
      if (o.code == 400) {
        h.status = bean.partnerStatus
        h.message = bean.remark
        return h
      }
      if (
        isDefine(o.api_status)
        || isDefine(o.trade_status)
        || isDefine(o.status)
      ) {
        h.status = o.api_status || o.trade_status || o.status
      } else {
        h.status = ''
      }
      if (!isDefine(o.message)) {
        h.message = ''
      } else {
        h.message = o.message
      }
      if (o.created_at) {
        h.created_at = formatTimeReg(o.created_at)
      } else {
        h.created_at = ''
      }
      return h
    },
    checkStatus() {
      if (!this.basicInfo.transactionId) return
      this.$showLoading()
      const res = this.$http.post('/api/v1/withdraw/repair', {
        transactionId: this.basicInfo.transactionId,
      })
      res
        .then(response => {
          if (response.data.code == 200) {
            location.reload()
            showToast(this, 'success', response.data.message)
          } else {
            this.$hideLoading()
            showToast(this, 'warning', response.data.message)
          }
        })
        .catch(error => {
          this.$hideLoading()
          showToast(this, 'warning', error.message)
        })
    },
  },
  setup(props, context) {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.withdraw-detail {
  width: 80%;
  padding: 4rem 0;
  margin: 0 auto;
  min-height: 400px;
  .basic-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  ::v-deep .history-table {
    word-break: break-all;
    word-wrap: break-all;
    .table th,
    .table td {
      padding: 10px !important;
    }
    .table tr td:nth-of-type(1) {
      width: 8.4rem;
    }
    .table tr td:nth-of-type(2) {
      width: 12.7rem;
    }
  }
  .per-page-selector {
    width: 90px;
  }
  .userinfo-form label {
    width: 100px;
    justify-content: left;
  }
  .invoice-filter-select {
    min-width: 280px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  .d-inline-block {
    width: 280px;
  }
}
</style>
